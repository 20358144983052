<template>
  <div class="flex flex-col gap-8">
    <div class="grid grid-cols-1 gap-6">
      <MopfButtonsGroup :buttons="buttons" />
    </div>
    <!-- <div
      class="xl:relative overflow-hidden h-auto border-2 border-gray-100 rounded-xl bg-white"
    >
      <div class="flex sm:flex-col md:flex-row">
        <div class="py-8 pl-6">
          <span class="text-3xl tracking-tighter font-medium"
            >Overall Performance</span
          >
        </div>
        <div
          class="flex justify-between gap-4 py-7 pr-10 xl:absolute xl:top-0 xl:right-0 sm:ml-auto lg:ml-auto sm:flex-col lg:flex-row"
        >
          <v-select
            class="sm:w-64 lg:w-36 xl:w-72"
            :options="practices"
            label="practice_name"
            @update:modelValue="setPracticeForAverage"
            buttonWidth="sm:w-52 lg:w-36 xl:w-64"
            placeholder="Practice"
          />
          <v-select
            class="sm:w-64 lg:w-36 xl:w-72"
            :options="yearsList"
            buttonWidth="sm:w-52 lg:w-36 xl:w-64"
            placeholder="Select Year"
            @update:modelValue="setYearForAverage"
          />
        </div>
      </div>
      <div class="border-b-2 border-[#ECECEC] drop-shadow-2xl w-full"></div>
      <div
        class="text-gray-600 tracking-light text-md font-medium pt-6 pb-2 pl-8"
      >
        Total Achieved
      </div>
      <QOFBarChart
        :key="averageByMonth"
        :data="averageByMonth"
        class="h-full"
      />
    </div>
    <div class="border-2 border-gray-100 rounded-xl">
      <IndicatorsTable
        :key="indicators"
        :indicatorStats="indicatorStats"
        :tableData="indicatorStats.data"
        :tableHeadings="surveyTableHeadings"
        :searchPlaceholder="'Search Indicator'"
        @orderBy="indicatorsFilters"
        @searchIndicator="indicatorsFilters"
        @pagination="indicatorsFilters"
        @practiceFilter="indicatorsFilters"
      />
    </div> -->
    <div class="border-2 border-gray-100 bg-white rounded-xl">
      <div class="flex flex-row items-center py-1 lg:px-0 sm:px-6">
        <div class="justify-items-start p-4 pl-6 text-lg w-full">
          <p>Action Plans for QOF</p>
        </div>
        <div class="flex space-x-4 justify-end p-4 pr-10 w-2/3 z-10">
          <v-select
            class="sm:w-64 lg:w-36 xl:w-72"
            :options="practices"
            label="practice_name"
            @update:modelValue="actionPlanPractice"
            buttonWidth="sm:w-52 lg:w-36 xl:w-64"
            placeholder="Practice"
          />
          <v-select
            class="sm:w-64 lg:w-36 xl:w-72"
            label="label"
            placeholder="Status"
            :searchable="false"
            :options="statusList"
            v-model="status"
            @update:modelValue="actionPlanStatus"
          >
          </v-select>
        </div>
      </div>
      <ActionPlanTable
        :key="actionPlansTableData?.data"
        :tableData="actionPlansTableData?.data"
        :tableHeadings="tableHeadings"
        :routeName="actionPlanDetailsRoute"
        :loader="loadingActionPlanTable"
      />
      <Pagination
        v-if="actionPlansTableData?.data?.length > 0"
        :currentPage="actionPlansTableData?.current_page"
        :totalPages="actionPlansTableData?.last_page"
        @page-changed="fetchPage"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

import Pagination from "@/components/newUi/atoms/Pagination.vue";

import MopfButtonsGroup from "@/components/newUi/baseComponents/MopfButtonsGroup.vue";
// import QOFBarChart from "@/components/newUi/charts/QOFBarChart.vue";
import ActionPlanTable from "@/components/newUi/tables/ActionPlanTable.vue";
// import IndicatorsTable from "@/components/newUi/tables/IndicatorsTable.vue";

const store = useStore();
// const indicators = ref([]);
const indicatorStats = ref([]);
const loadingActionPlanTable = ref(true);
const statusList = ref([
  { label: "In-Progress", value: "in-progress" },
  { label: "Completed", value: "completed" },
]);
const tableHeadings = ref([
  "Agenda",
  "Sub Indicator",
  "Practice",
  "Creation Date",
  "Meeting Count",
  "Meeting Date",
  "Agenda Status",
  "Timeframe",
  "",
]);
// const surveyTableHeadings = ref(["Name", "Percentage", "Points", ""]);
const buttons = ref([
  // {
  //   component: "hq-addIndicator",
  //   text: "Add Indicators",
  //   icon: "inbox_outlined",
  //   bgColor: "bg-teal",
  //   textColor: "text-teal",
  // },
  // {
  //   component: "hq-qof-view-edit",
  //   text: "Add QOF Data",
  //   icon: "tab",
  //   bgColor: "bg-yellow",
  //   textColor: "text-yellow",
  // },
  {
    component: "hq-createActionPlan-qof",
    params: { qof: true },
    text: "Create Action Plan",
    icon: "backup_table",
    bgColor: "bg-aquamarine",
    textColor: "text-aquamarine",
  },
]);
const actionPlanDetailsRoute = ref("hq-actionPlan-detail-qof");
const status = ref(null);
const practices = ref([]);
const actionPlanStatusValue = ref(null);
const actionPlanPracticeValue = ref(null);
// const practiceForAverage = ref(null);
// const yearForAverage = ref(null);

onMounted(async () => {
  fetchAllActionPlans({ tag: "qof", page: 1 });
  await store.dispatch("hqQof/fetchAverageByMonth");
  indicatorStats.value = await store.dispatch("hqQof/fetchIndicatorStats", {
    page: 1,
  });

  practices.value = await store.dispatch("patientSurveys/loadPractices");
});

// const indicatorsFilters = async (payload) => {
//   console.log(payload);
//   indicatorStats.value = await store.dispatch(
//     "hqQof/fetchIndicatorStats",
//     payload
//   );
// };

const fetchAllActionPlans = async (payload) => {
  loadingActionPlanTable.value = true;
  await store
    .dispatch("actionPlans/fetchAllQOFActionPlans", payload)
    .then(() => {
      loadingActionPlanTable.value = false;
    });
};

const actionPlansTableData = computed(() => {
  return store.getters["actionPlans/getActionPlans"];
});

// const yearsList = computed(() => {
//   var currentYear = new Date().getFullYear(),
//     years = [];
//   var startYear = 2023;
//   while (startYear <= currentYear) {
//     years.push(startYear++);
//   }
//   return years;
// });

// const averageByMonth = computed(() => {
//   return store.getters["hqQof/getAverageByMonth"];
// });

const actionPlanStatus = (status) => {
  actionPlanStatusValue.value = status?.value;
  if (status != null) {
    fetchAllActionPlans({
      tag: "qof",
      status: status.value,
      practice: actionPlanPracticeValue.value,
      page: 1,
    });
  } else {
    fetchAllActionPlans({
      tag: "qof",
      practice: actionPlanPracticeValue.value,
      page: 1,
    });
  }
};

const actionPlanPractice = (practice) => {
  actionPlanPracticeValue.value = practice?.id;
  if (practice != null) {
    fetchAllActionPlans({
      tag: "qof",
      practice: practice.id,
      status: actionPlanStatusValue.value,
      page: 1,
    });
  } else {
    fetchAllActionPlans({
      tag: "qof",
      status: actionPlanStatusValue.value,
      page: 1,
    });
  }
};

const fetchPage = (page) => {
  if (page > actionPlansTableData.value.last_page) {
    return;
  } else if (status.value != null) {
    fetchAllActionPlans({ tag: "qof", status: status.value.value, page: page });
  } else {
    fetchAllActionPlans({ tag: "qof", page: page });
  }
};

// const setPracticeForAverage = async (practice) => {
//   if (practice) {
//     practiceForAverage.value = practice.id;
//     await store.dispatch("hqQof/fetchAverageByMonth", {
//       practice: practice.id,
//       year: yearForAverage.value,
//     });
//   } else {
//     await store.dispatch("hqQof/fetchAverageByMonth", {
//       year: yearForAverage.value,
//     });
//   }
// };

// const setYearForAverage = async (year) => {
//   if (year) {
//     yearForAverage.value = year;
//     await store.dispatch("hqQof/fetchAverageByMonth", {
//       year: year,
//       practice: practiceForAverage.value,
//     });
//   } else {
//     await store.dispatch("hqQof/fetchAverageByMonth", {
//       practice: practiceForAverage.value,
//     });
//   }
// };
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
</style>
